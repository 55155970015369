import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { Constant } from "../constant/constant";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public emailValidationPattern: RegExp;

  constructor(private constant: Constant) {
    this.emailValidationPattern = this.constant.EMAIL_VALIDATION_PATTERN;
  }

  getVacationName(id, timeoffTypesList): string {
    let vacationName = "";
    timeoffTypesList.forEach((element) => {
      if (element.id == id) {
        vacationName = (element || {}).label || "";
      }
    });
    return vacationName;
  }

  getVacationLabelColor(id, timeoffTypesList): string {
    let vacationLabelColor = "";
    timeoffTypesList.forEach((element) => {
      if (element.id == id) {
        vacationLabelColor = (element || {}).label_color || "";
      }
    });
    return vacationLabelColor;
  }

  getDate(date) {
    if (!!date) {
      return DateTime.fromISO(date).toFormat(" dd LLL yyyy");
    }
  }
  getManagerName(id, employeesList): string {
    let managerName = "";
    employeesList.forEach((element) => {
      if (element.id == id) {
        managerName =
          element.name.last +
          ", " +
          element.name.first +
          " " +
          element.name?.middle;
      }
    });
    return managerName;
  }

  getEmployeeName(id, employeesList): string {
    let empName = "";
    employeesList.forEach((element) => {
      if (element.id == id) {
        empName =
          element.name.last +
          ", " +
          element.name.first +
          " " +
          element.name?.middle;
      }
    });
    return empName;
  }

  validateEmail(email: string): boolean {
    return this.emailValidationPattern.test(email);
  }

  formatToLocalTimeFromUTC = (utcDateString: string): Date => {
    const utcDateTime: Date = new Date(utcDateString);
    const localDateTime: Date = new Date(
      utcDateTime.getTime() + utcDateTime.getTimezoneOffset() * 60 * 1000
    );
    return localDateTime;
  };

  utcToJSStr = (isoDateStr: string): Date | string => {
    if (isoDateStr) {
      const dateObj = DateTime.fromISO(isoDateStr, { zone: "utc" });
      const jsDate = new Date(dateObj.year, dateObj.month - 1, dateObj.day);
      jsDate.setHours(0, 0, 0, 0);
      return DateTime.fromJSDate(jsDate).toFormat("MMM d, yyyy");
    }
    return isoDateStr;
  };

  utcToJSStartOfDay = (isoDateStr: string): Date | string => {
    if (isoDateStr) {
      const dateObj = DateTime.fromISO(isoDateStr, { zone: "utc" });
      const jsDate = new Date(dateObj.year, dateObj.month - 1, dateObj.day);
      jsDate.setHours(0, 0, 0, 0);
      return jsDate;
    }
    return isoDateStr;
  };

  jsDateToUTCStartOfDay = (jsDate: Date): string => {
    if (!jsDate) return "";

    if (!(jsDate instanceof Date)) return jsDate;

    return DateTime.utc(
      jsDate.getFullYear(),
      jsDate.getMonth() + 1,
      jsDate.getDate()
    )
      .startOf("day")
      .toISO();
  };
}
